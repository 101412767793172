<template>
  <b-row>
    <b-col>
      <b-card class="p-1 p-md-3">
        <b-card-title>
          {{ $t("deposit-text") }}
        </b-card-title>
        <b-row>
          <b-col sm="12">
            <b-alert
              variant="success"
              show
            >
              <div class="alert-body p-2">
                <h4>
                  {{ $t("deposit-alert-text") }}
                </h4>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <div
            class="d-flex flex-column flex-md-row w-100 justify-content-center align-items-center"
          >
            <b-button
              v-if="onlineBankDeposit"
              class="d-flex justify-content-center align-items-center btn-style"
              variant="success"
              :to="{
                name: 'forexModule-online-deposit',
                params: { id: $route.params.id },
              }"
            >
              <div>
                <h4 class="text-white">
                  {{ $t("bank_deposit") }}
                </h4>
                <span>{{ "(" + $t("online-payment") + ")" }}</span>
                <br>
                <br>
                <span>{{ $t("immediately") }}</span>
              </div>
            </b-button>
            <b-button
              v-if="isEnabledBankDeposit"
              class="d-flex justify-content-center align-items-center btn-style"
              variant="success"
              :to="{
                name: 'forexModule-bank-deposit',
                params: { id: $route.params.id },
              }"
            >
              <div>
                <h4 class="text-white">
                  {{ $t("bank_deposit") }}
                </h4>
                <span>{{ "(" + $t("bank-receipt") + ")" }}</span>
                <br>
                <br>
                <span>{{ $t("24 - 48") }} {{ $t("hour") }}</span>
              </div>
            </b-button>
            <b-button
              v-if="exchangeDeposit"
              class="d-flex justify-content-center align-items-center btn-style"
              variant="primary"
              :to="{
                name: 'forexModule-externalExchange-deposit',
                params: { id: $route.params.id },
              }"
            >
              <div>
                <h4 class="text-white">
                  {{ $t("external-exchange-deposit") }}
                </h4>
                <span>{{ $t("immediately") }}</span>
              </div>
            </b-button>
            <b-button
              class="d-flex justify-content-center align-items-center btn-style"
              variant="info"
              :to="{
                name: 'forexModule-crypto-deposit',
                params: { id: $route.params.id },
              }"
            >
              <div>
                <h4 class="text-white">
                  {{ $t("crypto Deposit") }}
                </h4>
                <span>{{ $t("1") }} {{ $t("hour") }}</span>
              </div>
            </b-button>
          </div>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BButton, BRow, BCol, BCardTitle, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BAlert,
  },
  data() {
    return {}
  },
  computed: {
    onlineBankDeposit() {
      return (
        process.env.VUE_APP_IS_ENABLED_FOREX_BANK_ONLINE_DEPOSIT === 'true'
      )
    },
    exchangeDeposit() {
      return process.env.VUE_APP_IS_ENABLED_FOREX_EXCHANGE_DEPOSIT === 'true'
    },
    isEnabledBankDeposit() {
      const isEnabled = process.env.VUE_APP_IS_ENABLED_FOREX_BANK_DEPOSIT
      return (
        isEnabled === 'true'
      )
    },
  },
}
</script>
<style>
.btn-style {
  margin: 5px;
  width: 100%;
  height: 100px;
}
</style>
