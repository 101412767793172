var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',{staticClass:"p-1 p-md-3"},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t("deposit-text"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-alert',{attrs:{"variant":"success","show":""}},[_c('div',{staticClass:"alert-body p-2"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("deposit-alert-text"))+" ")])])])],1)],1),_c('b-row',[_c('div',{staticClass:"d-flex flex-column flex-md-row w-100 justify-content-center align-items-center"},[(_vm.onlineBankDeposit)?_c('b-button',{staticClass:"d-flex justify-content-center align-items-center btn-style",attrs:{"variant":"success","to":{
              name: 'forexModule-online-deposit',
              params: { id: _vm.$route.params.id },
            }}},[_c('div',[_c('h4',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("bank_deposit"))+" ")]),_c('span',[_vm._v(_vm._s("(" + _vm.$t("online-payment") + ")"))]),_c('br'),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("immediately")))])])]):_vm._e(),(_vm.isEnabledBankDeposit)?_c('b-button',{staticClass:"d-flex justify-content-center align-items-center btn-style",attrs:{"variant":"success","to":{
              name: 'forexModule-bank-deposit',
              params: { id: _vm.$route.params.id },
            }}},[_c('div',[_c('h4',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("bank_deposit"))+" ")]),_c('span',[_vm._v(_vm._s("(" + _vm.$t("bank-receipt") + ")"))]),_c('br'),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("24 - 48"))+" "+_vm._s(_vm.$t("hour")))])])]):_vm._e(),(_vm.exchangeDeposit)?_c('b-button',{staticClass:"d-flex justify-content-center align-items-center btn-style",attrs:{"variant":"primary","to":{
              name: 'forexModule-externalExchange-deposit',
              params: { id: _vm.$route.params.id },
            }}},[_c('div',[_c('h4',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("external-exchange-deposit"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t("immediately")))])])]):_vm._e(),_c('b-button',{staticClass:"d-flex justify-content-center align-items-center btn-style",attrs:{"variant":"info","to":{
              name: 'forexModule-crypto-deposit',
              params: { id: _vm.$route.params.id },
            }}},[_c('div',[_c('h4',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("crypto Deposit"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t("1"))+" "+_vm._s(_vm.$t("hour")))])])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }